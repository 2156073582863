<template>
  <v-container class="edit-container px-10" fluid>
    <!-- Breadcrumbs -->
    <v-popup-loading :isLoading="isLoading" />
    <snack-bar v-model="isCreateSuccess" :content="snackbarSuccess"></snack-bar>
    <alert-error
      :show="isError"
      :msg="errorMessage"
      :close="closeAlert"
    ></alert-error>
    <alert-error
      :show="isContentFileError"
      :msg="contentFileErrorMessage"
      :close="closeContentFileAlert"
      :icon="''"
      title="Error Import file"
      headline-color="danger800Color"
      headline-class="body-bold"
      button-text="OK"
      button-color="info600Color"
    ></alert-error>
    <v-row class="mb-4">
      <v-col>
        <v-breadcrumbs
          :items="breadcrumbs"
          divider=">"
          class="px-0"
        ></v-breadcrumbs>
        <v-row class="align-center" style="margin-left: 1px; margin-right: 0;">
          <h3 class="pageHeadline" t-data="page-headline">
            Import File
          </h3>
        </v-row>
      </v-col>
    </v-row>

    <!-- Form -->
    <v-row align="start" justify="start" class="mt-0">
      <v-col cols="9" class="mt-0 pt-0">
        <v-form ref="form" lazy-validation>
          <v-card>
            <v-card-title class="mt-0 pt-0 pb-0" t-data="card-title">
              <h6 class="mb-0 card-title" t-data="info-title">
                Import Information
              </h6>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text class="mt-0">
              <v-row align="start" justify="start" class="mt-0">
                <v-col cols="6">
                  <p
                    class="body-regular warning-content mb-6"
                    t-data="warning-content"
                  >
                    All fields are required.
                  </p>
                </v-col>
              </v-row>
              <v-row
                :align="branchAlign"
                justify="start"
                class="mt-0 px-2"
                :style="branchStyle"
              >
                <v-col cols="7" class="pr-0" t-data="info-input">
                  <single-selected-branch
                    outlined
                    required
                    dense
                    title="Branch"
                    :branch="branches"
                    :selectedBranch="selectedBranch"
                    :syncData="changedSelectedBranch"
                    :rule="v => !!v || 'Branch is required'"
                    v-if="!isLoading"
                    :hide-details="branchHideDetails"
                    @change="checkValidType"
                  ></single-selected-branch>
                </v-col>
                <v-col class="mb-2">
                  <v-tooltip color="black" right>
                    <template v-slot:activator="{on, attrs}">
                      <v-icon
                        color="secondaryTextColor"
                        v-bind="attrs"
                        v-on="on"
                      >
                        mdi-information
                      </v-icon>
                    </template>
                    <span>
                      Importing files have to import files sequentially
                      <ol type="1">
                        <li>Department</li>
                        <li>Doctor</li>
                      </ol>
                    </span>
                  </v-tooltip>
                </v-col>
              </v-row>
              <v-chip
                v-for="(value, typeName) in validType"
                :key="typeName"
                class="pl-5 mb-4 mt-1 no-outline"
                :color="value ? 'success600Color' : 'secondaryTextColor'"
                t-data="type-status"
                outlined
                small
              >
                <v-icon size="13" left>
                  {{ value ? 'mdi-check-circle' : 'mdi-close-circle' }}
                </v-icon>
                {{ getTypeText(typeName) }} Added
              </v-chip>
              <v-row align="start" justify="start" class="mt-0 px-2">
                <v-col cols="7" class="pr-0" t-data="info-input">
                  <p class="edit-field-label sub-body-bold" t-data="info-label">
                    Type
                  </p>
                  <v-select
                    outlined
                    dense
                    class="can-disable-selector"
                    placeholder="Select Type"
                    v-model="fileObject.type"
                    :menu-props="{bottom: true, offsetY: true}"
                    :items="typeItems"
                    item-text="text"
                    item-value="value"
                    :disabled="!branchHideDetails"
                    :rules="[v => !!v || 'Type is required']"
                    @change="changedType"
                  ></v-select>
                </v-col>
              </v-row>
              <h6 class="mt-6 mb-3 card-title" t-data="info-label">
                Import Preference
              </h6>
              <v-row
                align="start"
                justify="start"
                class="mt-0 px-2"
                :style="preferenceStyle"
              >
                <v-col cols="12" t-data="info-input">
                  <v-radio-group
                    v-model="fileObject.preference"
                    class="mt-0"
                    :rules="[v => !!v || 'Import Preference is required']"
                    :disabled="disableEmptyBranch"
                    :hide-details="preferenceHideDetails"
                  >
                    <v-radio
                      v-for="item in preferenceItems"
                      :key="item.value"
                      :value="item.value"
                      :label="item.text"
                      color="secondary"
                      >{{ item }}</v-radio
                    >
                  </v-radio-group>
                </v-col>
              </v-row>
              <v-row class="mb-4 mt-1" v-if="preferenceHideDetails" t-data="">
                <v-col class="pl-5">
                  <v-chip
                    class="mr-2 no-outline"
                    color="danger600Color"
                    outlined
                    small
                  >
                    {{ preferenceWarningText }}
                  </v-chip>
                </v-col>
              </v-row>
              <div t-data="info-input">
                <p
                  class="edit-field-label sub-body-bold mt-4"
                  t-data="info-label"
                >
                  Import file
                </p>
                <excel-dropzone
                  class="can-disable-selector"
                  :thumbnail="fileObject.file"
                  :file-name="fileObject.filename"
                  :file-type="fileObject.fileType"
                  :is-error="fileIsError"
                  :error-msg="fileErrorMessage"
                  :disabled="fileObject.type === null"
                  :clear="clearUploadFile"
                  @update="updateImage"
                  @remove="removeImage"
                ></excel-dropzone>
              </div>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-text class="pt-6 pb-8">
              <v-row align="center" justify="center" class="mt-0 px-2">
                <v-btn
                  class="white-text-button button-bold"
                  color="primary600Color"
                  t-data="import-btn"
                  @click="save"
                >
                  import
                </v-btn>
              </v-row>
            </v-card-text>
          </v-card>
        </v-form></v-col
      >
      <v-col cols="3" class="mt-0 pt-0" t-data="activity-log-container">
        <v-card>
          <v-card-title class="mt-0 pt-0 pb-0" t-data="card-title">
            <h4 class="mb-0 card-title" t-data="info-title">
              Activity Log
            </h4>
          </v-card-title>
          <v-card-text>
            <activity-log :activity-logs="activityLogs"></activity-log>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {mapState, mapActions} from 'vuex'
import {
  departmentRules,
  doctorRules,
  preconditionTypes,
  preferenceEnum,
  preferenceText,
  typeEnum,
  typeText
} from '@/constants/import-file'
import ActivityLog from '@/components/import-file/ActivityLog'
import AlertError from '@/components/AlertError'
import PopupLoading from '@/components/PopupLoading'
import ExcelDropzone from '@/components/ExcelDropzone'
import SingleSelectedBranch from '@/components/SingleSelectedBranch'
import SnackBar from '@/components/SnackBar'
import {
  capitalizeFirstLetter,
  hasPermissionsByOrgs,
  isCsv,
  isXls,
  isXlsx,
  snackbarFailContent,
  snackbarSuccessContent,
  validateFile,
} from '@/helper/helper'

export default {
  components: {
    ActivityLog,
    AlertError,
    'v-popup-loading': PopupLoading,
    ExcelDropzone,
    SingleSelectedBranch,
    SnackBar
  },
  data() {
    return {
      isError: false,
      msgError: '',
      isContentFileError: false,
      contentFileErrorMessage:
        'Please double check your files before uploading<br>' +
        '<ol><li>Do not enter blanks for mandatory fields.</li>' +
        '<li>Necessary to fill in the information correctly according to the specified format.</li>' +
        '<li>The branch value in this file necessary consistent with the branch selected.</li></ol>',
      isCreateSuccess: false,
      showValidType: false,
      pageName: 'Import file',
      snackTitle: 'created',
      clearUploadFile: false
    }
  },
  async created() {
    await this.setOrgInfo()
  },
  beforeMount() {
    this.resetState()
  },
  methods: {
    closeAlert() {
      this.isError = false
    },
    closeContentFileAlert() {
      this.isContentFileError = false
      this.clearUploadFile = false
    },
    changedSelectedBranch(value) {
      if (value.length > 0) {
        this.fileObject.org_codename = value[0]
      }
    },
    changedType() {
      this.resetFileState()
    },
    async checkValidType(value) {
      const isSuccess = await this.checkType(value)
      if (isSuccess) {
        this.showValidType = true
      } else {
        this.isError = true
        this.msgError = this.errorMessage || 'Incomplete information'
      }
      await this.fetchActivityLogs(value)
      this.resetFileState()
    },
    getTypeText(type) {
      return typeText[type]
    },
    async updateImage(file) {
      if (file && file.name) {
        if (!isCsv(file) && !isXls(file) && !isXlsx(file)) {
          this.setFileError({
            isError: true,
            message: 'Only support .csv .xls .xlsx file!'
          })
          return
        } else if (!file || file.size > 2080000) {
          this.setFileError({
            isError: true,
            message: 'File size should be less than 2 MB!'
          })
          return
        } else {
          let rules = {}
          if (this.fileObject.type === typeEnum.DEPARTMENT) {
            rules = departmentRules
          }
          if (this.fileObject.type === typeEnum.DOCTOR) {
            rules = doctorRules
          }

          const isValid = await validateFile(file, rules, this.fileObject.org_codename)
          if (!isValid) {
            this.isContentFileError = true
            this.resetFileState()
            this.clearUploadFile = true
            return
          }
          this.setFile(file)
          this.setFileError({isError: false, message: ''})
          return
        }
      } else {
        this.resetFileState()
        this.setFileError({isError: false, message: ''})
      }
    },
    removeImage() {
      this.resetFileState()
      this.setFileError({isError: true, message: 'File is required'})
    },
    fileRules(val) {
      if (!val || val === '') {
        this.setFileError({
          isError: true,
          message: 'File is required'
        })
        return
      }
      this.setFileError({isError: false, message: ''})
      return
    },
    validate() {
      this.fileRules(this.fileObject.file)
      if (
        this.$refs.form.validate() &&
        !this.fileIsError &&
        this.fileObject.file !== ''
      ) {
        return true
      }
      return false
    },
    async save() {
      if (this.validate()) {
        try {
          const isSuccess = await this.import()
          if (isSuccess) {
            this.resetState()
            this.showValidType = false
            this.isCreateSuccess = true
            this.$refs.form.resetValidation()
          } else {
            this.isError = true
            this.msgError = this.errorMessage || 'Incomplete information'
          }
        } catch (e) {
          this.isError = true
          this.msgError = this.errorMessage || 'Incomplete information'
          return
        }
      }
    },
    ...mapActions('importFile', [
      'checkType',
      'fetchActivityLogs',
      'import',
      'resetFileState',
      'resetState',
      'setFile',
      'setFileError',
      'setOrgInfo'
    ])
  },
  computed: {
    breadcrumbs() {
      return [
        {text: 'Bulk Data', disabled: true, links: false},
        {text: 'Import', disabled: true, links: false}
      ]
    },
    snackbarSuccess() {
      return snackbarSuccessContent(this.pageName, this.snackTitle)
    },
    snackbarFail() {
      return snackbarFailContent(this.pageName, this.snackTitle)
    },
    disableEmptyBranch() {
      return this.fileObject.org_codename === null
    },
    branches() {
      return this.orgObject.map(o => o.value)
    },
    selectedBranch() {
      let selectedBranch = null
      if (this.fileObject.org_codename !== null) {
        selectedBranch = this.fileObject.org_codename
      }
      return selectedBranch
    },
    branchAlign() {
      return this.branchHideDetails ? 'end' : 'center'
    },
    branchStyle() {
      return this.branchHideDetails ? 'margin-bottom: 0' : 'margin-bottom: 18px'
    },
    branchHideDetails() {
      return this.showValidType
    },
    preferenceStyle() {
      return this.preferenceHideDetails
        ? 'margin-bottom: 0'
        : 'margin-bottom: 6px'
    },
    preferenceHideDetails() {
      return this.fileObject.preference !== ''
    },
    preferenceWarningText() {
      return this.fileObject.preference === preferenceEnum.ADD_UPDATE
        ? 'If uploading a file from an existing one The system will overwrite the original file. and cannot edit or restore the original file.'
        : ''
    },
    typeItems() {
      const items = []
      const hasPermission = type =>
        hasPermissionsByOrgs(`CanBulk${capitalizeFirstLetter(type)}`, [
          this.fileObject.org_codename
        ])
      const isAdded = type =>
        this.validType !== null &&
        preconditionTypes[type].every(p => this.validType[p])

      Object.keys(typeEnum).forEach(key => {
        const value = typeEnum[key]
        if (hasPermission(value) && isAdded(value)) {
          items.push({
            value: value,
            text: typeText[value]
          })
        }
      })
      return items
    },
    preferenceItems() {
      return Object.keys(preferenceEnum).map(t => ({
        value: preferenceEnum[t],
        text: preferenceText[preferenceEnum[t]]
      }))
    },
    ...mapState('importFile', [
      'activityLogs',
      'errorMessage',
      'fileErrorMessage',
      'fileIsError',
      'fileObject',
      'isLoading',
      'orgObject',
      'validType'
    ])
  }
}
</script>

<style scoped></style>
