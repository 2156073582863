<template>
  <div class="py-0 custom-timeline">
    <v-timeline dense v-if="activityLogs.length > 0">
      <v-timeline-item
        v-for="(log, i) in activityLogs"
        :key="i"
        color="none"
        icon-color="info400Color"
        icon="mdi-plus"
        fill-dot
        right
      >
        <v-row justify="space-between" t-data="activity-container">
          <v-col class="pt-0 body-regular">
            <span class="body-bold">{{ getName(log) }}</span> made a created on Import
            <span class="body-bold">{{ firstUppercase(log.type) }}</span>
          </v-col>
        </v-row>
        <v-row justify="space-between" t-data="date-container">
          <v-col class="pt-0 sub-body-regular">
            {{ dateFmt(log.create_at) }}
          </v-col>
        </v-row>
        <v-row justify="space-between" t-data="file-container">
          <v-col class="py-0 sub-body-regular">
            <div class="highlight-bg">{{ log.filename }} has been updated.</div>
          </v-col>
        </v-row>
      </v-timeline-item>
    </v-timeline>
    <div
      v-else
      class="text-center disableTextColor--text sub-body-regular mt-10 mb-15"
      t-data="log-empty"
    >
      If an activity log is displayed,<br />
      it will be displayed in this area.
    </div>
  </div>
</template>

<script>
import {capitalizeFirstLetter, convertDate} from '@/helper/helper'
import storages from '@/apis/local-storages'
const authStorage = storages.authLocalStorage()

export default {
  name: 'ActivityLog',
  props: {
    activityLogs: {type: Array}
  },
  methods: {
    getName(log) {
      return log.create_by_user.user_id === authStorage.getUser().user_id ? 'You' : log.create_by_user.name
    },
    dateFmt: date => convertDate(date),
    firstUppercase: text => capitalizeFirstLetter(text)
  }
}
</script>

<style lang="scss" scoped>
.highlight-bg {
  background-color: var(--grayscale-color);
  padding: 6px 12px;
}
</style>
