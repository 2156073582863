<template>
  <div>
    <div
      t-data="image-container"
      :class="{
        'image-upload-box-error': isError,
        'image-upload-box': !isError,
        'text-center d-flex flex-column align-center align-self-center align-content-center justify-center': true
      }"
    >
      <span
        t-data="image-remove-button"
        v-if="thumbnail && !disabled"
        class="ma-2 image-remove-button"
        color="whiteColor"
        @click="remove"
      >
        <v-icon light>
          mdi-close
        </v-icon>
      </span>
      <div
        v-if="thumbnail"
        class="text-center d-flex flex-column align-center align-self-center align-content-center justify-center"
      >
        <v-img
          src="/images/excel-logo.png"
          aspect-ratio="1"
          max-height="48"
          max-width="48"
          contain
          class="preview-uploaded-image mb-2"
        ></v-img>
        <p class="image-upload-box-title mb-1" t-data="image-container-title">
          {{ fileName }}
        </p>
        <p class="image-upload-box-info-title sub-body-regular" t-data="image-container-content">
          {{ fileType }}
        </p>
      </div>
      <input
        ref="uploadInput"
        v-if="!thumbnail"
        type="file"
        accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
        class="input-file"
        :disabled="disabled"
        @change="update"
        @click:clear="remove"
        required
      />
      <div
        v-if="!thumbnail"
        class="text-center d-flex flex-column align-center align-self-center align-content-center justify-center"
      >
        <v-img
          src="/images/excel-logo.png"
          aspect-ratio="1"
          max-height="48"
          max-width="48"
          contain
          class="preview-uploaded-image mb-2"
        ></v-img>
        <p class="image-upload-box-title mb-1" t-data="image-container-title">
            Drop your file here or
            <span :class="{'input-file-text': !disabled, 'image-upload-box-info-title text-decoration-underline': disabled}" t-data="browse-text">browse</span>
        </p>
        <p class="image-upload-box-info-title sub-body-regular" t-data="image-container-content">
            (Support .xls and .xlsx, .csv files Max size 2 MB)
        </p>
      </div>
    </div>
    <div class="sales-price-preview-box-error-message">
      <v-row
        t-data="error-message"
        align="center"
        justify="start"
        class="flex-nowrap"
        v-if="isError"
      >
        <p
          style="marginLeft:10px"
          class="edit-error-message sub-body-regular mb-0"
        >
          {{ errorMsg }}
        </p>
      </v-row>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ExcelDropzone',
  props: {
    thumbnail: {type: String},
    fileName: {type: String},
    fileType: {type: String},
    isError: {type: Boolean},
    errorMsg: {type: String},
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    clear: {
      type: Boolean,
      required: false,
      default: false
    },
  },
  watch: {
    clear() {
      if (this.clear) {
        this.$refs.uploadInput.value = ''
      }
    }
  },
  methods: {
    remove() {
      this.$emit('remove')
    },
    update(e) {
      const target = e.target
      const file = target.files[0]
      this.$emit('update', file)
    }
  }
}
</script>
